.app__about{
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__profiles{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
}
.app__profile-item{
    width: 190px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;

    img{
        width: 100%;
        height: 120px;
        border-radius: 15px;
        object-fit: cover;

    }
    @media screen and (min-width:2000px) {
       
    img{
        height: 320px;
    } 
    }
}